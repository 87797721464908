import React from 'react'
import { graphql } from 'gatsby'
import styles from './index-page.module.scss'

// Components
import Layout from '../components/Layout'
import Container from '../components/Layout/Container'
import FeatureList from '../components/Features/FeatureList'
import FaqList from '../components/Faqs/FaqList'
import Plans from '../components/Pricing/Plans'
import Jumbotron from '../img/Jumbotron'

export const IndexPageTemplate = ({
  jumbotron,
  text,
  features,
  plans,
  faqs
}) => (
  <article>
    <div className={styles.jumbotron}>
      <span className={styles.background} />
      <Container>
        <div className={styles.contentWrapper}>
          <div className={styles.text}>
            <h1 className={styles.header}>{jumbotron}</h1>
            <span className={styles.subtext}>{text}</span>
            <div>
              <a
                className={styles.shopifyBadge}
                href='https://apps.shopify.com/cart-saver'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src='/img/shopify-badge.svg' alt='Cart Saver App Page' />
              </a>
            </div>
          </div>
          <div className={styles.jumbotronImage}>
            <Jumbotron />
          </div>
        </div>
      </Container>
    </div>
    <FeatureList features={features} />
    <Plans plans={plans} />
    <FaqList faqs={faqs} />
  </article>
)

const IndexPage = ({ data, ...rest }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <IndexPageTemplate
        jumbotron={frontmatter.jumbotron}
        text={frontmatter.text}
        features={frontmatter.features}
        plans={frontmatter.plans}
        faqs={frontmatter.faqs}
        {...rest}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        jumbotron
        text
        features {
          name
          description
          icon
        }
        plans {
          name
          price
          features
        }
        faqs {
          q
          a
        }
      }
    }
  }
`

export default IndexPage
