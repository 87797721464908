import React from 'react'
import Section from '../Layout/Section'
import styles from './FaqList.module.scss'

const FaqList = ({ faqs }) => (
  <Section title="Frequently Asked Questions">
    <ul className={styles.faqList}>
      {faqs.map((faq, index) =>
        <li key={index} className={styles.faqItem}>
          <h3 className={styles.faqItemQuestion}>{faq.q}</h3>
          <p>
            {faq.a}
          </p>
        </li>  
      )}
    </ul>
  </Section>
)

export default FaqList