import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'
import styles from './Button.module.scss'

const Button = ({ 
  primary, 
  secondary, 
  fullWidth, 
  external, 
  children, 
  to, 
  ...rest 
}) => {
  if (external) {
    return (
      <a
        className={classNames(
          styles.btn,
          { [`${styles.primary}`]: primary },
          { [`${styles.secondary}`]: secondary },
          { [`${styles.fullWidth}`]: fullWidth }
        )}
        target="_blank"
        rel="noopener noreferrer"
        href={to}
        {...rest}
      >
        <span>{children}</span>
      </a>
    )
  }
  
  if (to) {
    return (
      <Link
        className={classNames(
          styles.btn,
          { [`${styles.primary}`]: primary },
          { [`${styles.secondary}`]: secondary },
          { [`${styles.fullWidth}`]: fullWidth }
        )}
        to={to}
        {...rest}
      >
        <span>{children}</span>
      </Link>
    )
  }

  return (
    <button className={classNames(styles.btn, { [`${styles.primary}`]: primary })}>
      <span>{children}</span>
    </button>
  )
}

export default Button
