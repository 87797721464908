import React from 'react'
import Section from '../Layout/Section'
import Button from '../Elements/Button'
import styles from './Plans.module.scss'

const Plans = ({ plans }) => (
  <Section>
    <div className={styles.plans}>
      {plans.map((plan, index) =>
        <div key={index} className={styles.plan}>
          <div className={styles.priceBox}>
            <h3 className={styles.name}>{plan.name}</h3>
            <span className={styles.price}>${plan.price}</span>/mo
          </div>
          <ul className={styles.features}>
            {plan.features.map((feature, index) =>
              <li key={index} className={styles.featuresItem}>
                {feature}
              </li>
            )}
          </ul>
          <div className={styles.action}>
            <Button primary external to="https://apps.shopify.com/cart-saver">Try for Free</Button>
          </div>
        </div>
      )}
    </div>
  </Section>
)

export default Plans