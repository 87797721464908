import React from 'react'
import styles from './FeatureItem.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FeatureItem = ({ feature }) => {
  return (
    <li className={styles.featureItem}>
      <div className={styles.icon}>
        <FontAwesomeIcon icon={feature.icon} size="1x" />
      </div>
      <div className={styles.featureInfo}>
        <h3 className={styles.featureTitle}>{feature.name}</h3>
        <p className={styles.description}>{feature.description}</p>
      </div>
    </li>
  )
}

export default FeatureItem