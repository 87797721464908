import React from 'react'
import styles from './FeatureList.module.scss'

// Components
import Section from '../Layout/Section'
import FeatureItem from './FeatureItem'

const FeatureList = ({ features }) => {
  return (
    <Section>
      <ul className={styles.productList}>
        {features.map((feature, index) => 
          <FeatureItem key={index} feature={feature} />
        )}
      </ul>
    </Section>
  )
}

export default FeatureList